import { useEffect } from 'react';
import { useWindowSize } from '@react-hookz/web';

export function HeightCalc() {
  const { height } = useWindowSize();

  useEffect(() => {
    if (height) {
      const vh = height * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    }
  }, [height]);

  return <></>;
}
