import { fetcher } from '@/lib/api';
import { PermissionCode } from '@/pages/w/sc/(subcompanyId)/settings/workspace/roles-and-permissions/models/role';
import React, { createContext, useEffect, useMemo, useState } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';

type IAuth = ReturnType<typeof AuthProvider>['value'];

export const AuthContext = createContext<IAuth>({} as IAuth);

export type Subcompany = {
  name: string;
  id: string;
  avatar: string | null;
  permissions: Record<PermissionCode, boolean>;
};
export type Workspace = { id: string; name: string | null };
export type WorkspaceUser = { id: string; avatar: string | null; first_name: string; last_name: string; email: string };

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [id, setId] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [firstName, setFirstName] = useState<string>();
  const [lastName, setLastName] = useState<string>();
  const [avatar, setAvatar] = useState<string | null>();
  const [permissions, setPermissions] = useState<Record<PermissionCode, boolean>>();
  const [language, setLanguage] = useState<string>();
  const [subcompanies, setSubcompanies] = useState<Subcompany[]>();
  const [workspace, setWorkspace] = useState<Workspace>();
  const [workspaceUsers, setWorkspaceUsers] = useState<WorkspaceUser[]>();

  const fullName = useMemo(() => {
    return [firstName, lastName].filter((text) => !!text).join(' ');
  }, [firstName, lastName]);

  const fullNameInitials = useMemo(() => {
    return fullName
      .split(' ')
      .map((name) => name.charAt(0).toUpperCase())
      .join('');
  }, [fullName]);

  const navigate = useNavigate();
  const location = useLocation();
  const { subcompanyId: paramsSubcompanyId } = useParams<{ subcompanyId: string }>();

  const currentSubcompany = useMemo(() => {
    if (!paramsSubcompanyId || !subcompanies) return undefined;
    return subcompanies.find((subcompany) => subcompany.id === paramsSubcompanyId);
  }, [subcompanies, paramsSubcompanyId]);

  const fetchMeToState = async () => {
    const data = await fetcher<{
      id: string;
      email: string;
      avatar: string | null;
      default_subcompany_id: string | null;
      first_name: string;
      last_name: string;
      language: string;
      subcompanies: Subcompany[];
      permissions: Record<PermissionCode, boolean>;
      workspace: Workspace;
      workspace_users: WorkspaceUser[];
    }>(`/users/me`, 'GET');

    setId(data.id);
    setAvatar(data.avatar);
    setEmail(data.email);
    setFirstName(data.first_name);
    setLastName(data.last_name);
    setLanguage(data.language);
    setSubcompanies(data.subcompanies);
    setPermissions(data.permissions);
    setWorkspace(data.workspace);
    setWorkspaceUsers(data.workspace_users);
    return data;
  };

  useEffect(() => {
    fetchMeToState()
      .then(({ default_subcompany_id, subcompanies }) => {
        if (location.pathname === '/') {
          if (default_subcompany_id) {
            navigate(`/w/sc/${default_subcompany_id}/dashboard`);
          } else {
            navigate(`/w/sc/${subcompanies?.[0].id}/dashboard`);
          }
        }
      })
      .catch(() => {
        if (!location.pathname.startsWith('/login')) {
          navigate(`/login/sign-in`);
        }
      });
  }, []);

  const getUserById = (id: string | null) => {
    if (id !== null) {
      return workspaceUsers?.find((user) => user.id === id);
    } else {
      return undefined;
    }
  };

  const updateWorkspaceName = (name: string) => {
    if (!workspace) return;
    setWorkspace((prev) => ({ ...prev!, name }));
  };

  const value = {
    id,
    setId,
    email,
    setEmail,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    fullName,
    fullNameInitials,
    avatar,
    setAvatar,
    language,
    setLanguage,
    subcompanies,
    setSubcompanies,
    currentSubcompany,
    permissions,
    workspace,
    workspaceUsers,
    updateWorkspaceName,
    setWorkspaceUsers,
    setPermissions,
    fetchMeToState,
    getUserById,
  } as const;

  return {
    ...(<AuthContext.Provider value={value}>{children}</AuthContext.Provider>),
    value,
  };
}
