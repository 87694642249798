import { useFetch } from '@/lib/api';
import { StripeV2Product } from '@/lib/enums/billing';
import { SWR_KEYS } from '@/lib/enums/swr';
import { createContext, useContext, useMemo } from 'react';
import { AuthContext } from './AuthContext';

type IBilling = ReturnType<typeof BillingProvider>['value'];

export const BillingContext = createContext<IBilling>({} as IBilling);

const FREE_ACCOUNT_MESSAGES = 1000;

export default function BillingProvider({ children }: { children: React.ReactNode }) {
  const { id } = useContext(AuthContext);
  const { data: activeProducts } = useFetch<StripeV2Product[]>(
    SWR_KEYS.GET_ACTIVE_PRODUCTS,
    id ? '/billing/get-active-products-v2' : null
  );

  const activeAddonsMap = useMemo<Record<StripeV2Product, boolean> | null>(() => {
    if (activeProducts) {
      const _activeAddonsMap = {} as Record<StripeV2Product, boolean>;
      for (const product of Object.values(StripeV2Product)) {
        _activeAddonsMap[product] = activeProducts.includes(product);
      }
      return _activeAddonsMap;
    } else {
      return null;
    }
  }, [activeProducts]);

  const isWorkspaceFreeTier = useMemo(() => {
    if (activeAddonsMap) {
      return Object.values(activeAddonsMap).every((a) => a === false) || activeAddonsMap[StripeV2Product.FREE] === true;
    } else {
      return null;
    }
  }, [activeAddonsMap]);

  // Proto AICX Base Message Free Tier
  const { data: freePeriodMessageCount } = useFetch<{ count: number; avg_days_left: number }>(
    SWR_KEYS.GET_FREE_PERIOD_MESSAGE_COUNT,
    isWorkspaceFreeTier && id ? '/analytics/get-free-period-message-count' : null
  );
  const canFreeAccountUseMessage = useMemo(() => {
    if (freePeriodMessageCount) {
      return freePeriodMessageCount.count < FREE_ACCOUNT_MESSAGES;
    } else {
      return null;
    }
  }, [freePeriodMessageCount]);

  const value = {
    activeAddonsMap,
    isWorkspaceFreeTier,
    freePeriodMessageCount,
    canFreeAccountUseMessage,
  } as const;

  return {
    ...(<BillingContext.Provider value={value}>{children}</BillingContext.Provider>),
    value,
  };
}
