import * as PlatformErrors from './platform';
import { ProtocxCoreError } from './base';

export const ERROR_BY_CODE: Record<string, typeof ProtocxCoreError> = {};

Object.values(PlatformErrors).forEach((error) => {
  if (error.prototype instanceof ProtocxCoreError) {
    ERROR_BY_CODE[error.code] = error as typeof ProtocxCoreError;
  }
});
