import React, { createContext, useContext, useEffect, useRef, useState } from 'react';
import { useLocalStorageValue, useWindowSize } from '@react-hookz/web';
import { AuthContext } from './AuthContext';
import { useFetch } from '@/lib/api';
import { SWR_KEYS } from '@/lib/enums/swr';
import { WhiteLabel } from '@/lib/schemas/white_label';
import { parseToHsla } from 'color2k';
import { defaultThemeHex } from '@/lib/helpers/colors';

type ITheme = ReturnType<typeof ThemeProvider>['value'];

export const ThemeContext = createContext<ITheme>({} as ITheme);

export default function ThemeProvider({ children }: { children: React.ReactNode }) {
  const { subcompanies } = useContext(AuthContext);
  const { value: theme, set: setTheme } = useLocalStorageValue<'system' | 'light' | 'dark'>('theme', {
    initializeWithValue: true,
    defaultValue: 'system',
  });

  const { value: isSidebarOpen, set: setIsSidebarOpen } = useLocalStorageValue<boolean>('sidebar_open', {
    initializeWithValue: true,
    defaultValue: true,
  });

  const [resolvedTheme, setResolvedTheme] = useState<'light' | 'dark'>('light');

  const { width } = useWindowSize();

  useEffect(() => {
    if (width < 1024) {
      setIsSidebarOpen(false);
    }
  }, [width]);

  useEffect(() => {
    if (theme === 'system') {
      const prefersDarkScheme = window.matchMedia('(prefers-color-scheme: dark)');
      if (prefersDarkScheme.matches) {
        document.documentElement.classList.add('dark');
        setResolvedTheme('dark');
      } else {
        document.documentElement.classList.remove('dark');
        setResolvedTheme('light');
      }
    } else if (theme === 'light') {
      document.documentElement.classList.remove('dark');
      setResolvedTheme('light');
    } else if (theme === 'dark') {
      document.documentElement.classList.add('dark');
      setResolvedTheme('dark');
    }
  }, [theme]);

  const [sidebarSubcompanies, setSidebarSubcompanies] = useState<{ name: string; id: string; avatar: string | null }[]>(
    []
  );

  useEffect(() => {
    if (subcompanies) {
      setSidebarSubcompanies(
        subcompanies.map((subcompany) => ({
          name: subcompany.name,
          id: subcompany.id,
          avatar: subcompany.avatar,
        }))
      );
    }
  }, [subcompanies]);

  const { data: whiteLabelConfiguration } = useFetch<WhiteLabel>(
    SWR_KEYS.GET_WORKSPACE_WHITE_LABEL_CONFIGURATION,
    '/workspace/white-label/get-white-label-configuration'
  );

  const editedProperties = useRef(new Set<string>());
  useEffect(() => {
    const hexToShadcnCompliantHsla = (hex: string) => {
      const [hue, saturation, lightness, _] = parseToHsla(hex);
      const decimalToPercentage = (decimal: number) => Math.round(decimal * 100);
      return `${hue} ${decimalToPercentage(saturation)}% ${decimalToPercentage(lightness)}%`;
    };

    if (whiteLabelConfiguration && Object.values(whiteLabelConfiguration).some((ele) => ele !== null)) {
      const propertyRoot = document.querySelector(resolvedTheme === 'light' ? ':root' : '.dark') as HTMLElement;
      if (propertyRoot) {
        const colors = Object.entries(whiteLabelConfiguration).filter(
          ([key]) => key.startsWith(`wl_${resolvedTheme}`) && key.endsWith('_color')
        );

        for (const [key, value] of colors) {
          if (value === null) {
            if (key === `wl_${resolvedTheme}_brand_color`) {
              // Brand color gets treated as a special property
              if (editedProperties.current.has('brand-color')) {
                const defaultPropertyValue: string | undefined = defaultThemeHex[resolvedTheme]['brand'];
                if (defaultPropertyValue) {
                  propertyRoot.style.setProperty(`--brand-color`, defaultPropertyValue);
                  editedProperties.current.delete('brand-color');
                }
              }
            } else {
              const propertyName = key.replace(`wl_${resolvedTheme}_`, '').replace('_color', '').replace(/_/g, '-');
              if (editedProperties.current.has(propertyName)) {
                const defaultPropertyValue: string | undefined = defaultThemeHex[resolvedTheme][propertyName];
                if (defaultPropertyValue) {
                  const propertyValue = hexToShadcnCompliantHsla(defaultPropertyValue);
                  propertyRoot.style.setProperty(`--${propertyName}`, propertyValue);
                  editedProperties.current.delete(propertyName);
                }
              }
            }
          } else {
            if (key === `wl_${resolvedTheme}_brand_color`) {
              // Brand color gets treated as a special property
              propertyRoot.style.setProperty(`--brand-color`, value);
              editedProperties.current.add('brand-color');
            } else {
              const propertyName = key.replace(`wl_${resolvedTheme}_`, '').replace('_color', '').replace(/_/g, '-');
              const propertyValue = hexToShadcnCompliantHsla(value!);
              propertyRoot.style.setProperty(`--${propertyName}`, propertyValue);
              editedProperties.current.add(propertyName);
            }
          }
        }
      }
    } else if (import.meta.env.VITE_BRAND !== 'Proto') {
      // Set all properties to default if whiteLabelConfiguration is not available
      const propertyRoot = document.querySelector(resolvedTheme === 'light' ? ':root' : '.dark') as HTMLElement;

      const defaultProperties = Object.entries(defaultThemeHex[resolvedTheme]);
      for (const [key, value] of defaultProperties) {
        const propertyValue = hexToShadcnCompliantHsla(value);
        if (propertyRoot) {
          propertyRoot.style.setProperty(`--${key}`, propertyValue);
        }
      }
    }
  }, [whiteLabelConfiguration, resolvedTheme]);

  const value = {
    theme,
    setTheme,
    resolvedTheme,
    isSidebarOpen,
    setIsSidebarOpen,
    sidebarSubcompanies,
    setSidebarSubcompanies,
    whiteLabelConfiguration,
  } as const;

  return {
    ...(<ThemeContext.Provider value={value}>{children}</ThemeContext.Provider>),
    value,
  };
}
