export function cssHslToHslaFunc(hsl: string, a: number) {
  const hslParts = hsl.split(' ');
  return `hsla(${hslParts[0]}, ${hslParts[1]}, ${hslParts[2]}, ${a})`;
}

export const defaultThemeHex: Record<string, Record<string, string>> = {
  light:
    import.meta.env.VITE_BRAND === 'Proto'
      ? {
          brand: '#f9c727',
          background: '#ffffff',
          foreground: '#09090b',
          card: '#ffffff',
          'card-foreground': '#09090b',
          popover: '#ffffff',
          'popover-foreground': '#09090b',
          primary: '#18181b',
          'primary-foreground': '#fafafa',
          secondary: '#f4f4f5',
          'secondary-foreground': '#18181b',
          muted: '#f4f4f5',
          'muted-foreground': '#71717a',
          accent: '#f4f4f5',
          'accent-foreground': '#18181b',
          border: '#e4e4e7',
          input: '#e4e4e7',
          ring: '#18181b',
        }
      : {
          brand: '#9123FF',
          background: '#ffffff',
          foreground: '#09090b',
          card: '#6372FF',
          'card-foreground': '#09090b',
          popover: '#ffffff',
          'popover-foreground': '#09090b',
          primary: '#9123FF',
          'primary-foreground': '#fafafa',
          secondary: '#f4f4f5',
          'secondary-foreground': '#18181b',
          muted: '#F1EFF3',
          'muted-foreground': '#71717a',
          accent: '#f4f4f5',
          'accent-foreground': '#18181b',
          border: '#e4e4e7',
          input: '#e4e4e7',
          ring: '#9123FF',
        },
  dark:
    import.meta.env.VITE_BRAND === 'Proto'
      ? {
          brand: '#f9c727',
          background: '#09090b',
          foreground: '#fafafa',
          card: '#09090b',
          'card-foreground': '#fafafa',
          popover: '#09090b',
          'popover-foreground': '#fafafa',
          primary: '#fafafa',
          'primary-foreground': '#18181b',
          secondary: '#27272a',
          'secondary-foreground': '#fafafa',
          muted: '#27272a',
          'muted-foreground': '#a1a1aa',
          accent: '#27272a',
          'accent-foreground': '#fafafa',
          border: '#27272a',
          input: '#27272a',
          ring: '#d4d4d8',
        }
      : {
          brand: '#9123FF',
          background: '#171519',
          foreground: '#EBE4EA',
          card: '#6372FF',
          'card-foreground': '#FFFFFF',
          popover: '#2F2C32',
          'popover-foreground': '#FFFFFF',
          primary: '#B46BFF',
          'primary-foreground': '#ffffff',
          secondary: '#6372FF',
          'secondary-foreground': '#FAFAFA',
          muted: '#2f2e32',
          'muted-foreground': '#818181',
          accent: '#B46BFF',
          'accent-foreground': '#FAFAFA',
          border: '#222124',
          input: '#4F4B52',
          ring: '#9123FF',
        },
};
