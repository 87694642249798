export class ProtocxCoreError<T = any> extends Error {
  code: string;
  data: T | undefined;

  constructor(message: string, code: string, data?: T) {
    super(message);
    this.name = 'ProtocxCoreError';
    this.code = code;
    this.data = data;
  }
}

export type PydanticValidationErrorObject = {
  ctx: {
    error: string;
  };
  input: string;
  loc: string[];
  msg: string;
  type: string;
};

export class PydanticValidationError extends ProtocxCoreError<PydanticValidationErrorObject[]> {
  static code = 'PYDANTIC_VALIDATION_ERROR';

  constructor(message: string, code: string, data: PydanticValidationErrorObject[]) {
    super(message, code || PydanticValidationError.code, data);
    this.name = 'PydanticValidationError';
  }
}
