import React, { createContext } from 'react';
import { useLocalStorageValue } from '@react-hookz/web';
import { useNavigate } from 'react-router-dom';

type INotification = ReturnType<typeof NotificationProvider>['value'];

export const NotificationContext = createContext<INotification>({} as INotification);

export default function NotificationProvider({ children }: { children: React.ReactNode }) {
  const navigate = useNavigate();

  const { value: isNotificationsEnabled, set: setIsNotificationsEnabled } = useLocalStorageValue<boolean>(
    'notifications_enabled',
    {
      initializeWithValue: true,
      defaultValue: false,
    }
  );

  const enableNotifications = async () => {
    if (!('Notification' in window)) {
      throw new Error('This browser does not support desktop notifications.');
    } else {
      const requestPermissionGranted = await Notification.requestPermission();
      if (requestPermissionGranted === 'granted') {
        setIsNotificationsEnabled(true);
      } else {
        setIsNotificationsEnabled(false);
        throw new Error('You do not have permission to enable notifications.');
      }
    }
  };

  const disableNotifications = () => {
    setIsNotificationsEnabled(false);
  };

  const notify = ({ title, body, to, onClick }: { title: string; body: string; to?: string; onClick?: () => void }) => {
    if (isNotificationsEnabled) {
      const notification = new Notification(title, { body });
      notification.onclick = () => {
        onClick?.();

        if (to) {
          navigate(to);
        }
      };
    }
  };

  const value = {
    notify,
    enableNotifications,
    disableNotifications,
    isNotificationsEnabled,
  } as const;

  return {
    ...(<NotificationContext.Provider value={value}>{children}</NotificationContext.Provider>),
    value,
  };
}
