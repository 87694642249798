export enum StripeV2Product {
  FREE = 'free',
  LITE = 'lite',
  PRO = 'pro',
  TOTAL = 'total',
  ENTERPRISE = 'enterprise',
}

export enum StripeBillingPortalFlowType {
  PAYMENT_METHOD_UPDATE = 'payment_method_update',
}
